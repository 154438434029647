import { AdminMenuItem } from "../types";

const dashboardIcon = `${process.env.PUBLIC_URL}/assets/dashboard-icon.svg`;
const workbenchIcon = `${process.env.PUBLIC_URL}/assets/workbench.svg`;
const transfersIcon = `${process.env.PUBLIC_URL}/assets/TransactionIcon.svg`
const withdrawlsIcon = `${process.env.PUBLIC_URL}/assets/arrow-down.svg`
const contributionsIcon = `${process.env.PUBLIC_URL}/assets/interactive-icon.svg`
const clientDetailsIcon = `${process.env.PUBLIC_URL}/assets/client-details.svg`
const financialAdvisorIcon = `${process.env.PUBLIC_URL}/assets/financial-advisor.svg`
const wishesIcon = `${process.env.PUBLIC_URL}/assets/whishes.svg`
const historyIcon = `${process.env.PUBLIC_URL}/assets/docImage.svg`;
const biosIcon= `${process.env.PUBLIC_URL}/assets/bios.svg`;
const goodLooksIcon= `${process.env.PUBLIC_URL}/assets/good-looks.svg`;
const marketPlayersIcon= `${process.env.PUBLIC_URL}/assets/market-player.svg`;

export const SIPP_ADMIN_MENU_ITEMS: AdminMenuItem[] = [
  {
    text: "Bios",
    icon: biosIcon,
    link: "bios",
    isUserAllowed: true
  },
  {
    text: "What Good Looks Like",
    icon: goodLooksIcon,
    link: "good-looks",
    isUserAllowed: true
  },
  {
    text: "Market Players",
    icon: marketPlayersIcon,
    link: "market-players",
    isUserAllowed: true
  },
  {
    text: "Client",
    icon: clientDetailsIcon,
    link: "client-profile",
    isUserAllowed: true
  }
];

export const NEW_APPLICATION_SIPP_TABS= ["Product Details", "Client Details", "Contribution Types", "Financial Advisor Fees", "Confirm", "Submitted"] as NEW_APPLICATION_SIPP_TAB[];
export enum NEW_APPLICATION_SIPP_TAB {
  PRODUCT_DETAILS= "Product Details",
  CLIENT_DETAILS = "Client Details",
  CONTRIBUTION_TYPES = "Contribution Types",
  FINANCIAL_ADVISOR_FEES = "Financial Advisor Fees",
  CONFIRM= "Confirm",
  SUBMITTED= "Submitted"
}

export const CONTRIBUTION_TYPE_TABS= ["Investment Contribution", "Transfer Contribution"] as CONTRIBUTION_TYPE_TAB[];
export enum CONTRIBUTION_TYPE_TAB {
  INVESTMENT_CONTRIBUTION = "Investment Contribution",
  TRANSFER_CONTRIBUTION = "Transfer Contribution",
  DEFAULT= "Default"
}

export const INVESTMENT_TYPE_TABS= ["Lump Sum Buy", "Phased Buy", "Stock Re-Registration"] as INVESTMENT_TYPE_TAB[];
export enum INVESTMENT_TYPE_TAB {
  LUMP_SUM = "Lump Sum Buy",
  PHASED_BUY = "Phased Buy",
  STOCK_REREGISTRATION = "Stock Re-Registration",
  DEFAULT= "Default"
}

export const PRODUCT_TYPE_TABS= ["ISA", "SIPP", "GIA"] as PRODUCT_TYPE_TAB[];
export enum PRODUCT_TYPE_TAB {
  ISA = "ISA",
  SIPP = "SIPP",
  GIA = "GIA",
  DEFAULT= "Default"
}
