// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    FieldName,
    SingleRow,
    Span,
    AccordionRowsHolder
} from "styles/sippAdmin/clientProfile.styled";
import AliceModalTooltip from "components/AliceModalTooltip";
import NoDataContent from "components/noData/NoDataContent";
import ClientProfileCommentsSectionComponent from "./CommentSectionComponent";
import { AirTableRecord } from "types";

const ClientProfileAccordionComponent: FunctionComponent<{ 
    selectedClient: any,
    title: string,
    opened: boolean,
    noTopBorder: boolean,
    handleCommentClick: (component: string) => void,
    handleEditComment: (comment: AirTableRecord) => void,
    airtableData: any[];
    loadingAirtable: boolean;
}> = ({
    title,
    selectedClient,
    opened,
    noTopBorder,
    handleCommentClick,
    handleEditComment,
    airtableData,
    loadingAirtable
}) => {
    
  return (
        <Accordion title={title} opened={opened} noTopBorder={noTopBorder}>
            <>
                <AccordionRowsHolder twoColumns>
                {selectedClient && selectedClient[title] ? Object.keys(selectedClient[title]).map((field, index) => (
                    <SingleRow key={index} >
                        <FieldName>{field}</FieldName>
                        {Array.isArray(selectedClient[title][field]) ? 
                        (
                            <AliceModalTooltip selectedField={selectedClient[title][field][0]} selectedKey={field}>
                            <Span>{selectedClient[title][field][0]}</Span>
                            </AliceModalTooltip>
                        ) : 
                        (
                            <AliceModalTooltip selectedField={selectedClient[title][field] as string} selectedKey={field}>
                            <Span>{selectedClient[title][field]}</Span>
                            </AliceModalTooltip>
                        )}
                    </SingleRow>
                )) : <NoDataContent />}
                </AccordionRowsHolder>                
                <ClientProfileCommentsSectionComponent handleCommentClick={handleCommentClick} title={title} airtableData={airtableData} handleEditComment={handleEditComment} loadingAirtable={loadingAirtable}/> 
            </>
        </Accordion>
  );
}

export default ClientProfileAccordionComponent;
