import styled from "styled-components";

export const FundsContainer = styled.div`
  margin: 50px auto;
`;

export const RequestByDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

export const AdminFundsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const AdminFundsDashboardDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const ProgressWdgtDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CircleProgress = styled.div`
  width: 100px;
  height: 100px;
  display: inline-block;
`;

export const Circle = styled.svg`
  transform: rotate(-90deg);
  width: 100px;
  height: 100px;
`;

export const CircleBar = styled.circle`
  fill: none;
  stroke-width: 4;
  stroke: #364073;
  stroke-linecap: round;
  stroke-dasharray: 251.2;
  stroke-dashoffset: 10;
`;

export const CircleText = styled.div`
  position: relative;
  top: -63%;
  text-align: center;
  font-size: 16px;
  color: #364073;
`;

export const RiskRankingDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

export const RiskRankingOption = styled.div`
  color: red;
  font-size: 18px;
`;

export const DocumentsDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PaperClipDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

export const StatusBullets = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
`;

export const FundMarkersDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const FundMarkerItemDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 45px;
`;

export const MarkerAccordionDiv = styled.div`
  width: 400px;
`;

export const LinearProgressDiv = styled.div`
  width: 100px;
`;
export const FundCommentDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const CommentIcon = styled.img`
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
`;
