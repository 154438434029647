// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { useState, FunctionComponent, useEffect } from "react";
import { TemporarySippClientType } from "types/funds";
import { AirTableJSON, AirTableRecord } from "types";
import NewCommentComponent from "components/sippAdmin/NewCommentPopUp";
import { fetchAirTable } from "services/airTable";
import ClientProfileAccordionComponent from "./ClientProfileAccordionComponent";
import ClientProfileAccordionTableComponent from "./ClientProfileAccordionTableComponent";
import EditCommentComponent from "../EditCommentPopup";

const ClientProfileAccordionsComponent: FunctionComponent<{ 
  selectedClient: TemporarySippClientType | undefined; 
}> = ({
    selectedClient
}) => {
    const [airtableData, setAirTableData]= useState<any[]>([]);
    const [update, setUpdate] = useState<number>(0);
    const commentIcon = `${process.env.PUBLIC_URL}/assets/comment-icon3.svg`;
    const [popupVisible, setPopupVisible] = useState(false);
    const [component, setComponent] = useState<string>("");
    const [editComment, setEditComment] = useState<boolean>(false);
    const [selectedComment, setselectedComment] = useState<AirTableRecord>();
    const [loadingAirtable, setLoadingAirtable] = useState<boolean>(false);

    useEffect(() => {
        // retrieve the necessary data from AirTable
        setLoadingAirtable(true);
        fetchAirTable("SIPP Admin Comments").then((airTableJSON: AirTableJSON) => {
          setAirTableData(airTableJSON.records);
          setLoadingAirtable(false);
        });
    }, [update]);

    const handleCommentClick = (section: string) => {
        setComponent(section);
        setPopupVisible(!popupVisible);
    }

    const handleEditComment = (comment: AirTableRecord) => {
      setselectedComment(comment);
      setEditComment(!editComment);
    }
    
  return (
    <div>
        {popupVisible ? <NewCommentComponent alertIsActive={popupVisible} menuToggle={()=>setPopupVisible(!popupVisible)} component={component} setUpdate={setUpdate}/>  : null}

        {editComment ? <EditCommentComponent alertIsActive={editComment} menuToggle={()=>setEditComment(!editComment)} comment={selectedComment} setUpdate={setUpdate} /> : null}

        <ClientProfileAccordionComponent title="Client Details" selectedClient={selectedClient} opened={true} noTopBorder={true} handleCommentClick={handleCommentClick}  airtableData= {airtableData.filter(record => record.fields.Component === "Client Details")} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionComponent title="Financial Advisor" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Financial Advisor")} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable}/>

        <ClientProfileAccordionComponent title="Expression of Wishes/Beneficiary" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Expression of Wishes/Beneficiary")} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Assets" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Assets")} tableHeaders= {["Instrument Name", "ISIN", "Sedol", "figi"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Transfers" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Transfers")} tableHeaders= {["Ceding Scheme", "Value", "Transfer Type", "Pension Type", "Date of transfer", "Full/Partial", "Uncrystallised Value", "Crystallised Value", "Protection in Place", "Previously Taken Benefits"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Account" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Account")} tableHeaders= {["Account Type", "Total Value", "Uncrystallised Value", "Crystallised Value", "Tax Free Cash Taken", "Pension Income", "Income Longevity Prediction"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Contributions" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Contributions")} tableHeaders= {["Amount", "Frequency", "Income", "Contributor", "Tax Relief Received", "Date of Initial Contribution", "Contributions Current Year", "Carry Forward"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Bank Account/Transaction history" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Bank Account/Transaction history")} tableHeaders= {["Opening Balance", "Transaction Date", "Transaction Type", "Transaction Amount", "Note", "Rolling Balance", "Closing Balance"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

        <ClientProfileAccordionTableComponent title="Withdrawals/Drawdown" selectedClient={selectedClient} opened={false} noTopBorder={false} handleCommentClick={handleCommentClick} airtableData= {airtableData.filter(record => record.fields.Component === "Withdrawals/Drawdown")} tableHeaders= {["Drawdown Type", "Tax Free Lump Sum Taken", "Remaining TFLS", "UFPLS", "Income in Payment", "Date of Next Payment", "Tax code", "Protection in Place"]} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable} />

    </div>
  );
}

export default ClientProfileAccordionsComponent;
