import React, { FunctionComponent, ChangeEventHandler, KeyboardEvent, useState, useRef, useEffect } from "react";
import { 
    InstrumentSuggestions,
    FilterInputWrapper
} from "styles/funds/MasterBuylist.styled";
import { FilterInput, FilterIcon } from "styles/filter/Search.styled";
import { SEARCH_CLASS_TYPE } from "constants/filter";
import { filterFundsHubwiseData } from "./searchInstrumentUtils";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";
 import { SuggestionItem, SuggestionItemName } from "styles/searchSuggestion/SearchSuggestionItem.styled";
 import { 
    Loader,
    LoaderBar, 
    SuggestionNotFound
} from "styles/SearchSuggestion.styled";

const SearchInstrumentSuggestions : FunctionComponent<{
   dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
   setSelectedFund: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFundsType} | undefined>>;
    setSelectedFigi: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFigiMapType} | undefined>>;
    setSelectedfm: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryMasterInstrumentsFundManagerType;
    } | undefined>>;
    setSingleItemSelected :React.Dispatch<React.SetStateAction<boolean>>;
}> =  ({
    dataToDisplay,
    setSelectedFund,
    setSelectedFigi,
    setSelectedfm,
    setSingleItemSelected
}) => {  
    const [query, setQuery] = useState("");
    const [somethingSearched, setSomethingSearched]= useState<boolean>(false);
    const [ loading, setLoading ]= useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<{fmIndex: number; fundIndex: number; figiIndex: number; obj: TemporaryMasterInstrumentsFundManagerType }[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setQuery(event.target.value);
    };

    const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && query.trim() !== "") {
            event.preventDefault();
            event.stopPropagation();
            setSomethingSearched(true);
            setLoading(true);
            const result = await filterFundsHubwiseData(dataToDisplay, query.toLowerCase());
            setSuggestions(result);
            setLoading(false);
        } else if (query.length === 0) {
            setSuggestions([]);
            setSomethingSearched(false);
            setLoading(false);
        }
    }; 

    const handleSearchSuggestionClick = (fm : TemporaryMasterInstrumentsFundManagerType, fmIndex:number, fundIndex: number, figiIndex: number, initialFundIndex: number) => {
        setSelectedfm({index: fmIndex, obj: fm});
        setSelectedFund({index: initialFundIndex, obj: fm.funds[fundIndex]});
        if(fm.funds[fundIndex] && fm.funds[fundIndex].figi_map.length > 0) {
            setSelectedFigi({index: 0, obj: fm.funds[fundIndex].figi_map[0]});
        }
        setSingleItemSelected(true);
    }

    useEffect(() => {
        // focus the input on mounting the page
        inputRef.current?.focus();
    }, []);

    return (
        <InstrumentSuggestions noMargin={true}>
                <FilterInputWrapper classType={SEARCH_CLASS_TYPE.DEFAULT}>
                    <FilterIcon icon="searchIcon" />
                    <FilterInput
                        value={query}
                        onChange={handleChange}
                        placeholder="Search Asset..."
                        aria-label="input"
                        classType={SEARCH_CLASS_TYPE.DEFAULT}
                        onKeyUp={ handleKeyUp }
                        ref={inputRef}
                    />
                </FilterInputWrapper>
                {suggestions.length > 0 && !loading && (                
                    suggestions.map((suggestion, fmIndex) => (
                        <div key={fmIndex}>
                            {suggestion.obj && suggestion.obj.funds && suggestion.obj.funds.map((fund, fundIndex) =>(
                                fund.figi_map.length > 0 ? 
                                    (
                                        <SuggestionItem key={fundIndex} onClick={()=>handleSearchSuggestionClick(suggestion.obj, suggestion.fmIndex, fundIndex, suggestion.figiIndex, suggestion.fundIndex)}>
                                            <SuggestionItemName>{fund.figi_map[0].name}</SuggestionItemName>
                                        </SuggestionItem>
                                    ): (
                                        <SuggestionItem key={fundIndex} onClick={()=>handleSearchSuggestionClick(suggestion.obj, suggestion.fmIndex, fundIndex, suggestion.figiIndex, suggestion.fundIndex)}>
                                            <SuggestionItemName>{fund["Instrument Name"]}</SuggestionItemName>
                                        </SuggestionItem>
                                    )
                            ))}
                        </div>
                    ))                
                )}
                {suggestions.length == 0 && !loading && somethingSearched && (
                    <SuggestionNotFound data-testid="suggestion-not-found">
                        Nothing found, try a different search
                    </SuggestionNotFound>
                )}
                {loading &&
                    <Loader data-testid="loader">
                        <LoaderBar />
                    </Loader>
                }
            </InstrumentSuggestions>
    )
}

export default SearchInstrumentSuggestions;