import { useEffect } from "react";
import {
  ContentContainer,
  Title,
  CloseIcon,
  closeIcon,
} from "styles/crownAlert/CrownAlert.styled";
import { toggleScroll } from "utils";
import { DarkLayer } from "styles/sippAdmin/clientProfile.styled";
import { 
    QHolder,
    QAccordionHolder,
    Section,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder,
    MainContainer,
    ConfirmationContainer 
} from "styles/sippAdmin/sippAdmin.styled";
import { useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { updateAirtableRecord } from "services/airTable";
import { useAppData } from "hooks/useAppData";
import {
  assetDir,
  GreenIcon
} from "styles/AlertFeedback.styled";
import { AirTableRecord } from "types";
import { CommentStatus } from "components/filter/Index";

function EditCommentComponent({
    alertIsActive,
    menuToggle,
    header,
    comment,
    setUpdate
}: {
    alertIsActive: boolean;
    menuToggle: (a?: string, b?: string) => void;
    header?: boolean;
    comment?: AirTableRecord;
    setUpdate: React.Dispatch<React.SetStateAction<number>>
}) {
  useEffect(() => {
    toggleScroll(alertIsActive);
  }, [alertIsActive]);

  function handleCloseModal() {
    menuToggle();
  }

  const { user } = useAppData();

  const formObj = {
        "Status": "",
        "Comment": ""
    }

    const [formValues, setFormValues] = useState<{[key: string]: any}>(formObj); 
    const [confirmation, setConfirmation] = useState(false);
    const [status, setStatus] = useState("");

    const handleInputChange = (label: string, value: string) => {
    setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        console.log("comment: ", comment);

        const records = { 
            "records": [
                {
                    "id": comment?.id,
                    "fields": {
                        "Status": status !== "" ? status : comment?.fields.Status,
                        "Notes": formValues.Comment
                    }
                }
            ]
        }
        updateAirtableRecord("SIPP Admin Comments", records).then((response) => {
            console.log("add task response: ", response)
        })
        setConfirmation(true);
        setUpdate((prev) => prev + 1);
    };

  return (
    <div style={{ display: alertIsActive ? "flex" : "none" }} data-testid="cont">
      <DarkLayer />
      <MainContainer header={header}>
        <CloseIcon iconURL={closeIcon} onClick={handleCloseModal} />
        { !confirmation ?
        <ContentContainer>
          <Title>
            <b>Edit Comment</b>
          </Title>
            <QHolder onSubmit={handleSubmit}>
                <Section>
                    <QAccordionHolder>  
                        <QRow >
                            <FormLabel htmlFor={"Status"}>Status</FormLabel>
                            <CommentStatus setStatus={setStatus}/>
                        </QRow>
                        <QRow >
                            <FormLabel htmlFor={"Comment"}>Comment</FormLabel>
                            <InputWrapper>
                                <Input
                                    type="text"
                                    id={"Comment"}
                                    value={formValues["Comment"]}
                                    onChange={(e) => handleInputChange("Comment", e.target.value)}
                                />
                            </InputWrapper>
                        </QRow>
                    </QAccordionHolder>
                </Section>
            </QHolder>
            <SubmitBtnHolder><SubmitButton text="Submit" onClick={handleSubmit} type="submit" disabled={formValues["Comment"].trim() === "" ? true : false}/></SubmitBtnHolder>
        </ContentContainer>
        : 
        <ConfirmationContainer>
          <FormLabel>Thank you for your feedback!</FormLabel>
          <GreenIcon src={`${assetDir}checkmate-verified.svg`} alt="green status" />
        </ConfirmationContainer>
        }
      </MainContainer>
    </div>
  );
}
export default EditCommentComponent;
