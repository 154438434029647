// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container, 
    SubTitle,
    SubSubTitle,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    FormValue,
    InputWrapper,
    Input,
    SubmitBtnHolder ,
    InvestmentTypeDivHolder
} from "styles/sippAdmin/sippAdmin.styled";
import { FunctionComponent, useState, useEffect } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { 
    NEW_APPLICATION_SIPP_TAB, 
    INVESTMENT_TYPE_TAB
} from "constants/sippAdminMenuItems";
import clientData from "../../../data/clientProfileData.json";
import { TemporarySippNewApplicationType } from "types/funds";
import { InvestmentTypeFilter } from "components/filter/Index";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";
import HubwiseMasterBuylistSearchInstrumentComponent from "components/hubwise-dashboard/masterBuylist/searchInstrument/HubwiseBuylistSearchInstrument";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";

const SIPPNewInvestmentComponent: FunctionComponent<{
    setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>
    setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
    mainFormDetails?: TemporarySippNewApplicationType;
    data: TemporaryMasterInstrumentsFundManagerType[];
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
    loading?: boolean
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails,
    data,
    buylistsInfo,
    loading
}) => {

    const formObj = {
        "Investment Type": ""
    }

    console.log("assets: ", data);

    const [investmentType, setInvestmentType]= useState<INVESTMENT_TYPE_TAB>(INVESTMENT_TYPE_TAB.DEFAULT);
    const [dataToDisplay, setDataToDisplay]= useState<TemporaryMasterInstrumentsFundManagerType[]>(data);

    useEffect(()=>{
        setMainFormDetails(prevState => ({ ...prevState, "Investment Details": { ...mainFormDetails?.["Investment Details"], "Investment Type": investmentType}}))
    },[investmentType])

    useEffect(()=>{
        if(mainFormDetails?.["Investment Details"] === undefined) {
            setMainFormDetails(prevState => ({ ...prevState, "Investment Details": formObj}))
        }
    },[])

    const handleInputChange = (label: string, value: string) => {
        setMainFormDetails(prevState => ({ ...prevState, "Investment Details": { ...prevState?.["Investment Details"], [label]: value}}));
        
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.FINANCIAL_ADVISOR_FEES)
    };

    const handleBack = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CLIENT_DETAILS)
    };

  return (
    <Container>
        <SubSubTitle>
            Investment Details
        </SubSubTitle>
        <InvestmentTypeDivHolder>
            <InvestmentTypeFilter setCategory={setInvestmentType} localTabSelected={mainFormDetails?.["Investment Details"]?.["Investment Type"]}/>
        </InvestmentTypeDivHolder>
        <SubSubTitle>
            Assets
        </SubSubTitle>
        <Section>
            <QAccordionHolder twoColumns>  
                {loading ? <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv> : 
                <HubwiseMasterBuylistSearchInstrumentComponent buylistsInfo={buylistsInfo} dataToDisplay={data} setDataToDisplay={setDataToDisplay}/> }
            </QAccordionHolder>
        </Section>
        <SubmitBtnHolder>
            <SubmitButton
                onClick={handleBack}
                text={"Back"}
                type={"submit"}
                disabled={false}
            />
            <SubmitButton
                onClick={handleSubmit}
                text={"Next"}
                type={"submit"}
                disabled={false}
            />
        </SubmitBtnHolder>
       
    </Container>
  );
}

export default SIPPNewInvestmentComponent;
