import styled from "styled-components";
import { SEARCH_CLASS_TYPE } from "constants/filter";

export const Container = styled.div`
    padding: 32px;
`;

export const DashboardContainer = styled.div``;

export const Title= styled.div`
  font-size: 36px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
`;

export const SubTitle= styled.div`
  font-size: 26px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 600;
`;

export const SubSubTitle= styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 500;
  margin-top: 10px;
`;

export const ContentItem = styled.div`
    flex-basis: 50%;
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    font-size: 18px;
    line-height: 21.09px;
    padding-bottom: 10px;
`;

export const QHolder = styled.form`
  padding: 5px;
`;

export const Section = styled.div`
  margin-bottom: 12px;
`;

export const QAccordionHolder = styled.div<{twoColumns?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({twoColumns}) => twoColumns ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  gap: 15px;
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
  margin-top: 40px;
`;

export const QRow = styled.div<{twoColumns?: boolean}>`
    display: grid;
    grid-template-columns: repeat(auto-fit, 25% 70%);
    gap: 15px;
    row-gap: 15px;
    align-items: center;
    @media (max-width: 769px) {
      flex-direction: column;
      align-items: start;
    }
`;

export const InputWrapper = styled.div<{ classType?: SEARCH_CLASS_TYPE }>`
  display: flex;
  align-items: center;
  background-color: ${({ classType }) =>
    classType === SEARCH_CLASS_TYPE.PRIMARY && "rgba(54, 64, 115, 0.1)"};
  border: ${({ classType }) => classType === SEARCH_CLASS_TYPE.DEFAULT && "1px solid #D0D5DD"};
  padding: 13px 10px;
  border-radius: 6px;
  flex-basis: 100%;
  column-gap: 10px;
  width: 100%;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const Input = styled.input<{ classType?: SEARCH_CLASS_TYPE, border?: boolean}>`
  font-weight: 400;
  font-size: ${({ classType }) => (classType === SEARCH_CLASS_TYPE.DEFAULT ? "16px" : "12px")};
  outline: none;
  border: ${({ border }) => (border ? "1px solid red" : "none")};
  height: 56px;
  color: #000000;
  background: #F8F8F8;
  width: 100%;
  font-size: 16px;
  flex: 1;
  padding: 3px 40px 0 15px;
  &:focus {
    outline: 1px solid #4A82A6;
  }
`;

export const FormLabel = styled.label`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  margin-top: 25px;
  text-wrap: wrap;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const FormValue = styled.label`
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  width: 50%;
  margin-top: 25px;
  text-wrap: wrap;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const SubmitBtnHolder= styled.div`
  width: 20%;
  margin: 30px auto 30px;
`;

export const TeamBioDiv= styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

export const TeamBioCard= styled.div`
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
`;

export const TeamBioCardName= styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.PRIMARY[300]}
`;

export const TeamBioCardRole= styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  margin-bottom: 10px;
  font-weight: 600;
`;

export const TeamBioCardBio= styled.div`
  font-size: 15px;
  line-height: 16px;
  color: #777;
  margin-bottom: 5px;
  text-align: left;
`;

export const LinkedInLink= styled.a`
  align-self: flex-end;
`;

export const TeamBioCardImg= styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  align-self: center;
`;

export const MainContainer = styled.div<{ header?: boolean }>`
  height: max-content;
  width: 35%;
  border-radius: 8px;
  top: ${({ header }) => (header === true ? "42%" : "50%")};
  left: ${({ header }) => (header === true ? "76%" : "50%")};
  transform: translate(-50%, -50%);
  z-index: 8;
  position: fixed;
  background: ${({ theme }) => theme.colors.WHITE};
  font-family: "Roboto Condensed", "sans-serif";
  z-index: 10;
  @media (max-width: 768px) {
    width: 80%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  height: 45vh;
  width: 35vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const InvestmentTypeDivHolder= styled.div`
  margin: 20px 0;
  width: min-content;
`;