// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container,
    Title
} from "styles/sippAdmin/sippClient.styled";
import SIPPClientDetailsComponent from "components/sippAdmin/ClientDetailsComponent";
import { NEW_APPLICATION_SIPP_TAB } from "constants/sippAdminMenuItems";
import { useState } from "react";
import SIPPClientContributionTypesComponent from "./ContributionTypes";
import SIPPClientAdvisorFeesComponent from "./AdvisorFeesForm";
import { TemporarySippNewApplicationType } from "types/funds";
import SIPPClientConfirmAndSubmitComponent from "./ConfirmAndSubmit";
import SIPPProductDetailsComponent from "./ProductSelection";

function SIPPClientNewApplicationComponent() {

  const [mainTab, setMainTab] = useState<NEW_APPLICATION_SIPP_TAB>(NEW_APPLICATION_SIPP_TAB.PRODUCT_DETAILS);
  const [mainFormDetails, setMainFormDetails]= useState<TemporarySippNewApplicationType>();

  return (
    <Container>
        <Title>New Application</Title>
        { mainTab === NEW_APPLICATION_SIPP_TAB.PRODUCT_DETAILS && <SIPPProductDetailsComponent setTab={setMainTab} setMainFormDetails={setMainFormDetails} mainFormDetails= { mainFormDetails } /> }

        { mainTab === NEW_APPLICATION_SIPP_TAB.CLIENT_DETAILS && <SIPPClientDetailsComponent setTab={setMainTab} setMainFormDetails={setMainFormDetails} mainFormDetails= { mainFormDetails } /> }
        { mainTab === NEW_APPLICATION_SIPP_TAB.CONTRIBUTION_TYPES && <SIPPClientContributionTypesComponent setTab={setMainTab} setMainFormDetails={setMainFormDetails} mainFormDetails= { mainFormDetails }/> }
        { mainTab === NEW_APPLICATION_SIPP_TAB.FINANCIAL_ADVISOR_FEES && 
        <SIPPClientAdvisorFeesComponent setTab={setMainTab} setMainFormDetails={setMainFormDetails} mainFormDetails= { mainFormDetails }/> }
        {mainTab === NEW_APPLICATION_SIPP_TAB.CONFIRM && <SIPPClientConfirmAndSubmitComponent setTab={setMainTab} setMainFormDetails={setMainFormDetails} mainFormDetails= { mainFormDetails }/>
        }

    </Container>
  );
}

export default SIPPClientNewApplicationComponent;
