// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container, 
    Title,
    SubTitle,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { FunctionComponent, useEffect } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { NEW_APPLICATION_SIPP_TAB} from "constants/sippAdminMenuItems";
import { TemporarySippNewApplicationType } from "types/funds";

const SIPPClientDetailsComponent: FunctionComponent<{
    setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>;
    setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
    mainFormDetails?: TemporarySippNewApplicationType
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails
}) => {

    const formObj = {
        "Title": "",
        "Name": "",
        "Date of Birth": "",
        "Address Line 1": "",
        "Address Line 2": "",
        "Address Line 3": "",
        "Country": "",
        "City": "",
        "Post Code": "",
        "Gender": "",
        "Nationality": "",
        "NI Number": "",
        "Status (Married/ Single/ etc.)": "",
        "Income": "",
        "Source of Wealth": "",
        "Phone Number": "",
        "Email": "",
        "Retirement Age": "",
        "Bank Account Number": "",
        "Bank Sort Code": "",
        "Bank Name": "",
        "IBAN": "",
        "BIC": ""
    }

    useEffect(()=>{
        if(mainFormDetails?.["Client Details"] === undefined) {
            setMainFormDetails(prevState => ({ ...prevState, "Client Details": formObj}))
        }
    },[])

    const handleInputChange = (label: string, value: string) => {
        setMainFormDetails(prevState => ({ ...prevState, "Client Details": { ...prevState?.["Client Details"], [label]: value}}));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CONTRIBUTION_TYPES)
    };

    const handleBack = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.PRODUCT_DETAILS)
    };

  return (
    <Container>
        <SubTitle>
            Client Details
        </SubTitle>

        <QHolder onSubmit={handleSubmit}>
            <Section>
                <QAccordionHolder twoColumns>  
                    {mainFormDetails?.["Client Details"] &&
                        Object.entries(mainFormDetails["Client Details"]).map(([label, value]) => (
                            <QRow key={label}>
                                <FormLabel htmlFor={label}>{label}</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={label}
                                        value={value}
                                        onChange={(e) => handleInputChange(label, e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                        ))
                    }
                </QAccordionHolder>
            </Section>
        </QHolder>
        <SubmitBtnHolder>
            <SubmitButton
                onClick={handleBack}
                text={"Back"}
                type={"submit"}
                disabled={false}
            />
            <SubmitButton
                onClick={handleSubmit}
                text={"Next"}
                type={"submit"}
                disabled={false}
            />
        </SubmitBtnHolder>
       
    </Container>
  );
}

export default SIPPClientDetailsComponent;
