// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import React,{ FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import {
    AccordionRowsHolder 
} from "styles/sippAdmin/clientProfile.styled";
import NoDataContent from "components/noData/NoDataContent";
import { 
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable,
    TableHeaderRow
} from "styles/sippAdmin/clientProfile.styled";
import { TemporarySippClientType } from "types/funds";
import ClientProfileCommentsSectionComponent from "./CommentSectionComponent";
import { AirTableRecord } from "types";

const ClientProfileAccordionTableComponent: FunctionComponent<{ 
    tableHeaders: string[]; 
    selectedClient: TemporarySippClientType | undefined;
    handleCommentClick: (component: string) => void;
    handleEditComment: (component: AirTableRecord) => void;
    title: string;
    airtableData: any[];
    opened: boolean;
    noTopBorder: boolean;
    loadingAirtable: boolean;
}> = ({
    tableHeaders,
    selectedClient,
    handleCommentClick,
    handleEditComment,
    title,
    airtableData,
    opened,
    noTopBorder,
    loadingAirtable
}) => {

  return (
    <Accordion title={title} opened={opened} noTopBorder={noTopBorder}>
        <>
            <AccordionRowsHolder>
                <WrapTable>    
                    <RespTable>
                        <RespTableHeader >
                            <TableHeaderRow>
                                {tableHeaders.map((header, index) => (
                                    <TableHeaderCell key={index}>{header}</TableHeaderCell>
                                ))}
                            </TableHeaderRow>
                        </RespTableHeader>   
                        {
                            (selectedClient && selectedClient[title] && (Array.isArray(selectedClient[title])) && selectedClient[title].length > 0) ?  
                                <RespTableBody>
                                    {selectedClient[title].map((item: any, rowIndex: number) => (
                                        <React.Fragment key={rowIndex}>
                                            <RespTableRow  onClick={()=>console.log("clicked")} >
                                                {tableHeaders.map((header: string, indexL: number) => (
                                                    <TableBodyCell key={indexL}>{item[header]}</TableBodyCell>
                                                ))}
                                            </RespTableRow>
                                        </React.Fragment>
                                    ))}
                                </RespTableBody>
                            : <NoDataContent />
                        }
                    </RespTable>
                </WrapTable>
            </AccordionRowsHolder>
            <ClientProfileCommentsSectionComponent handleCommentClick={handleCommentClick} title={title} airtableData={airtableData} handleEditComment= {handleEditComment} loadingAirtable={loadingAirtable}/>
        </>
    </Accordion>
  );
}

export default ClientProfileAccordionTableComponent;
