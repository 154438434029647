// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container, 
    SubTitle,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { FunctionComponent, useEffect, useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { NEW_APPLICATION_SIPP_TAB} from "constants/sippAdminMenuItems";
import { TemporarySippNewApplicationType } from "types/funds";
import { PRODUCT_TYPE_TAB } from "constants/sippAdminMenuItems";
import { ProductTypeFilter } from "components/filter/Index";

const SIPPProductDetailsComponent: FunctionComponent<{
    setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>;
    setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
    mainFormDetails?: TemporarySippNewApplicationType
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails
}) => {

    const [productDetails, setProductDetails]= useState<PRODUCT_TYPE_TAB>(PRODUCT_TYPE_TAB.DEFAULT);

    useEffect(()=>{
        setMainFormDetails(prevState => ({ ...prevState, "Product Details": productDetails}));
    },[productDetails])

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CLIENT_DETAILS)
    };

  return (
    <Container>
        <SubTitle>
            Product Details
        </SubTitle>

        <QHolder onSubmit={handleSubmit}>
            <Section>
                <QAccordionHolder twoColumns>  
                    <QRow>
                        <ProductTypeFilter setCategory={setProductDetails} localTabSelected={mainFormDetails?.["Product Details"]}/>
                    </QRow>
                </QAccordionHolder>
            </Section>
        </QHolder>
        <SubmitBtnHolder>
            <SubmitButton
                onClick={handleSubmit}
                text={"Next"}
                type={"submit"}
                disabled={false}
            />
        </SubmitBtnHolder>
       
    </Container>
  );
}

export default SIPPProductDetailsComponent;
