// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    SubTitle,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    FormValue,
    InputWrapper,
    Input,
    SubmitBtnHolder,
    InvestmentTypeDivHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { FunctionComponent, useState, useEffect } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { NEW_APPLICATION_SIPP_TAB} from "constants/sippAdminMenuItems";
import clientData from "../../../data/clientProfileData.json";
import { TemporarySippNewApplicationType } from "types/funds";
import { InvestmentTypeFilter } from "components/filter/Index";
import { INVESTMENT_TYPE_TAB } from "constants/sippAdminMenuItems";

const SIPPTransferFormComponent: FunctionComponent<{
    setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>
    setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
    mainFormDetails?: TemporarySippNewApplicationType
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails
}) => {
    const [investmentType, setInvestmentType]= useState<INVESTMENT_TYPE_TAB>(INVESTMENT_TYPE_TAB.DEFAULT);
    
    const formObj = {
        "Ceding Provider": "",
        "Ceding Provider Client ID": "",
        "Cash Assets": [],
        "Stock Assets": [],
        "Combined Assets": [],
        "Investment Type": "",
        "Phased Frequency": "",
        "Phased Amount": "",
        "Phased Duration": ""
    }

    useEffect(()=>{
        if(mainFormDetails?.["Transfer Details"] === undefined) {
            setMainFormDetails(prevState => ({ ...prevState, "Transfer Details": formObj}))
        }
    },[])

    useEffect(()=>{
        setMainFormDetails(prevState => ({ ...prevState, "Transfer Details": { ...prevState?.["Transfer Details"], "Investment Type": investmentType}}));
    },[investmentType])

    const handleInputChange = (label: string, value: string) => {
        setMainFormDetails(prevState => ({ ...prevState, "Transfer Details": { ...prevState?.["Transfer Details"], [label]: value}}));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.FINANCIAL_ADVISOR_FEES)
    };

    const handleBack = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CLIENT_DETAILS)
    };

    const models= ["ARM - Asset Rotation Model", "SRM - Sector Rotation Model", "401(k) Allocation Model", "TSP Allocation Model"]

  return (
    <Container>
        <SubTitle>
            Transfer Details
        </SubTitle>

        <QHolder onSubmit={handleSubmit}>
            <Section>
                <QAccordionHolder twoColumns>  
                    <QRow>
                        <FormLabel htmlFor={"Ceding Provider"}>{"Ceding Provider"}</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Ceding Provider"}
                                value={mainFormDetails?.["Transfer Details"]?.["Ceding Provider"]}
                                onChange={(e) => handleInputChange("Ceding Provider", e.target.value)}
                            />
                        </InputWrapper>
                    </QRow>
                    <QRow>
                        <FormLabel htmlFor={"Ceding Provider Client ID"}>{"Ceding Provider Client ID"}</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Ceding Provider Client ID"}
                                value={mainFormDetails?.["Transfer Details"]?.["Ceding Provider Client ID"]}
                                onChange={(e) => handleInputChange("Ceding Provider Client ID", e.target.value)}
                            />
                        </InputWrapper>
                    </QRow>
                    
                </QAccordionHolder>
                <InvestmentTypeDivHolder>
                    <InvestmentTypeFilter setCategory={setInvestmentType}/>
                </InvestmentTypeDivHolder>
                <QAccordionHolder twoColumns>
                    
                    {investmentType === INVESTMENT_TYPE_TAB.PHASED_BUY && (
                        <>
                            <QRow>
                                <FormLabel htmlFor={"Phased Frequency"}>{"Phased Frequency"}</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Phased Frequency"}
                                        value={mainFormDetails?.["Transfer Details"]?.["Phased Frequency"]}
                                        onChange={(e) => handleInputChange("Phased Frequency", e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                            <QRow>
                                <FormLabel htmlFor={"Phased Amount"}>{"Phased Amount"}</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Phased Amount"}
                                        value={mainFormDetails?.["Transfer Details"]?.["Phased Amount"]}
                                        onChange={(e) => handleInputChange("Phased Amount", e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                            <QRow>
                                <FormLabel htmlFor={"Phased Duration"}>{"Phased Duration"}</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Phased Duration"}
                                        value={mainFormDetails?.["Transfer Details"]?.["Phased Duration"]}
                                        onChange={(e) => handleInputChange("Phased Duration", e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                        </>
                    )}
                </QAccordionHolder>
            </Section>
        </QHolder>
        <SubmitBtnHolder>
            <SubmitButton
                onClick={handleBack}
                text={"Back"}
                type={"submit"}
                disabled={false}
            />
            <SubmitButton
                onClick={handleSubmit}
                text={"Next"}
                type={"submit"}
                disabled={false}
            />
        </SubmitBtnHolder>
       
    </Container>
  );
}

export default SIPPTransferFormComponent;
