// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container,
    Title
} from "styles/sippAdmin/sippAdmin.styled";
import { 
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable,
    TableHeaderRow,
    TableContentContainer,
    SelectedClientHolder,
    CloseIcon,
    CloseIconHolder,
    closeIcon
} from "styles/sippAdmin/clientProfile.styled";
import clientData from "../../../data/clientProfileData.json";
import { useState } from "react";
import ClientProfileAccordionsComponent from "./ClientProfileAccordionsHolder";
import { TemporaryFundsType, TemporarySippClientType } from "types/funds";

function SIPPClientProfileComponent() {

    const [clientLData, setClientLData] = useState<TemporarySippClientType[]>(clientData);
    const [selectedClient, setselectedClient] = useState<TemporarySippClientType | undefined>(undefined);
    const [selectedsingleRow, setSelectedSingleRow] = useState<boolean>(false);

    const handleRowClick = (client: TemporarySippClientType) => {
        setSelectedSingleRow(true);
        setselectedClient(client);
    }

    const handleClose = () => {
        setSelectedSingleRow(false);
    }

    const allHeaders  = Object.keys(clientLData[0]["Client Details"]);
    const headers = allHeaders.slice(0, 7);

  return (
    <Container>
        <Title>Client Profile</Title>
        <TableContentContainer>
            { !selectedsingleRow ? 
                <WrapTable>
                    <RespTable>
                        <RespTableHeader>
                            <TableHeaderRow>
                                {headers.map((header, index) => (
                                    <TableHeaderCell key={index}>{header}</TableHeaderCell>
                                ))}
                            </TableHeaderRow>
                        </RespTableHeader>
                        {clientLData && 
                            <RespTableBody>
                                {clientLData.map((client: TemporarySippClientType, rowIndex) => (
                                    <RespTableRow key={rowIndex} onClick={()=>handleRowClick(client)} data-testid="row">
                                        {headers.map((header, index) => (
                                            <TableBodyCell key={index}>{client["Client Details"][header]}</TableBodyCell>
                                        ))}
                                    </RespTableRow> 
                                ))}
                            </RespTableBody>
                        }
                    </RespTable>
                </WrapTable>
                : 
                <SelectedClientHolder>
                    <CloseIconHolder>
                        <CloseIcon iconURL={closeIcon} onClick={handleClose} />
                    </CloseIconHolder>
                    <ClientProfileAccordionsComponent selectedClient={selectedClient}/>
                </SelectedClientHolder>
            }
        </TableContentContainer>
       
    </Container>
  );
}

export default SIPPClientProfileComponent;
