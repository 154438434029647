import styled from "styled-components";
import { SEARCH_CLASS_TYPE } from "constants/filter";

export const Container = styled.div`
    padding: 0 32px;
`;

export const Title= styled.div`
  font-size: 36px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
`;

export const ContributionsContainer= styled.div`
  padding: 32px 0;
`;