// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    ContributionsContainer
} from "styles/sippAdmin/sippClient.styled";
import { SubTitle } from "styles/sippAdmin/sippAdmin.styled";
import { CONTRIBUTION_TYPE_TAB, NEW_APPLICATION_SIPP_TAB } from "constants/sippAdminMenuItems";
import { FunctionComponent, useEffect, useState } from "react";
import { New_Contribution_Category } from "components/filter/Index";
import SIPPNewInvestmentComponent from "./InvestmentForm";
import SIPPTransferFormComponent from "./TransferForm";
import { TemporarySippNewApplicationType } from "types/funds";
import { InvestmentTypeDivHolder } from "styles/sippAdmin/sippAdmin.styled";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";
import { getAccessToken } from "services/auth0/auth0";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  HUBWISE_FUNDS_DETAILS_PATH
} from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";

const SIPPClientContributionTypesComponent: FunctionComponent<{
  setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>;
  setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
  mainFormDetails?: TemporarySippNewApplicationType
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails
}) => {

  const [localtab, setLocalTab] = useState<CONTRIBUTION_TYPE_TAB>(CONTRIBUTION_TYPE_TAB.DEFAULT);
  const [loading, setLoading]= useState<boolean>(false);
  const [ masterList, setMasterList ]= useState<TemporaryMasterInstrumentsFundManagerType[]>([]);
  const [buylistsInfo, setBuylistsInfo]= useState<{"Buylist Code": string, "Buylist Description": string}[]>([]);
  
  const token = getAccessToken();
  
  useEffect(()=>{    
          if(token) {
              setLoading(true);
              const masterBuylist = "master_buylist.json";
              const payload = {
                  "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                  "fileName": masterBuylist,
                  "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
              };
  
              callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
              .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
              .then((data: TemporaryMasterInstrumentsFundManagerType[])=> {
                  setMasterList(data);
                  setLoading(false);
              });
  
              const buylistFileName= "all_buylists.json"
  
              const buylistsPayload= {
                  "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                  "fileName": buylistFileName,
                  "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
              }
  
              callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, buylistsPayload, "test", "test")
              .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
              .then((data: {"Buylist Code": string, "Buylist Description": string}[])=> {
                  setBuylistsInfo(data);
              });
          }
      },[token]);

  useEffect(()=>{
    if(localtab !== CONTRIBUTION_TYPE_TAB.DEFAULT) {
      setMainFormDetails(prevState => ({ ...prevState, "Contribution Selected": localtab}))
    }
  },[localtab])

  return (
    <ContributionsContainer>
        <SubTitle>
            Contribution Details
        </SubTitle>
        <ContributionsContainer>
        <InvestmentTypeDivHolder>
          <New_Contribution_Category setCategory={setLocalTab} localTabSelected={mainFormDetails?.["Contribution Selected"]}/>
        </InvestmentTypeDivHolder>

          { localtab === CONTRIBUTION_TYPE_TAB.INVESTMENT_CONTRIBUTION  && <SIPPNewInvestmentComponent setTab={setTab} setMainFormDetails={setMainFormDetails} mainFormDetails= {mainFormDetails} data={masterList} buylistsInfo={buylistsInfo} loading={loading}/>}

          { localtab === CONTRIBUTION_TYPE_TAB.TRANSFER_CONTRIBUTION && <SIPPTransferFormComponent setMainFormDetails={setMainFormDetails} mainFormDetails= {mainFormDetails} setTab= {setTab}/>}
        </ContributionsContainer>
        
    </ContributionsContainer>
  );
}

export default SIPPClientContributionTypesComponent;
