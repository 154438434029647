/**
 * Function that calls AirTable API and returns dummy data from a specific table.
 * @param tableName: name of the specific table
 * @return an AirTableJSON value
 */ import { AirTableJSON } from "../types";

export const fetchAirTable = async (tableName: string): Promise<AirTableJSON> => {
  const airTableURl = `https://api.airtable.com/v0/appIUlHpC74uLZ5M0/${tableName}`;
  const airTableApiToken = "patffDvvFegSncZLy.12b4d06f5e6fb83696e0ce7290c54ba8f7b09860e962b714d0cbb344f62ff28c"; // TODO: security - remove this API Key
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${airTableApiToken}`,
    },
  };
  const airTableResponse = await fetch(airTableURl, fetchOptions);
  return airTableResponse.json();
};

export const addAirtableRecord = async (tableName: string, records: any): Promise<any> => {
  const airTableURl = `https://api.airtable.com/v0/appIUlHpC74uLZ5M0/${tableName}`;
  const airTableApiToken = "patffDvvFegSncZLy.12b4d06f5e6fb83696e0ce7290c54ba8f7b09860e962b714d0cbb344f62ff28c"; // TODO: security - remove this API Key
  const fetchOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${airTableApiToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(records)
  };
  const airTableResponse = await fetch(airTableURl, fetchOptions);
  return airTableResponse.json();
}

export const updateAirtableRecord = async (tableName: string, records: any): Promise<any> => {
  const airTableURl = `https://api.airtable.com/v0/appIUlHpC74uLZ5M0/${tableName}`;
  const airTableApiToken = "patffDvvFegSncZLy.12b4d06f5e6fb83696e0ce7290c54ba8f7b09860e962b714d0cbb344f62ff28c"; // TODO: security - remove this API Key
  const fetchOptions = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${airTableApiToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(records)
  };
  const airTableResponse = await fetch(airTableURl, fetchOptions);
  return airTableResponse.json();
}
