export enum SEARCH_CLASS_TYPE {
  DEFAULT = "is-default",
  PRIMARY = "is-primary",
}

export const DATE_RANGES: string[] = ["Today", "Previous 7 Days", "Previous 30 Days", "Default"];
export enum DATE_RANGE {
  TODAY = "Today",
  SEVEN_DAYS = "Previous 7 Days",
  THIRTY_DAYS = "Previous 30 Days",
  DEFAULT = "Default",
}

export const ALLFUNDS_REQUEST_RANGES: string[]= ["Today", "Previous 7 Days", "Previous 30 Days", "Previous 6 months", "Older than 6 months", "Default"]
export enum ALLFUNDS_REQUEST_DATE {
  TODAY = "Today",
  SEVEN_DAYS = "Previous 7 Days",
  THIRTY_DAYS = "Previous 30 Days",
  SIX_MONTHS = "Previous 6 months",
  MORE_THAN_SIX_MONTHS= "Older than 6 months",
  DEFAULT = "Default"
}

export const FILTER_STATUS: string[] = ["Open", "Closed", "Default"];
export enum STATUS {
  ACTIVE = "Open",
  CLOSED = "Closed",
  DEFAULT = "Default"
}

export const COMMENT_STATUS: string[] = ["In progress", "Todo", "Done"];
export enum C_STATUS {
  IN_PROGRESS = "In progress",
  TO_DO = "Todo",
  DONE = "Done",
  DEFAULT = "Default"
}

export const FILTER_ALLFUNDS_REQUEST_STATUSES: string[] = ["In Progress", "Completed", "Cancelled", "Default"];
export enum FILTER_ALLFUNDS_REQUEST_STATUS {
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  DEFAULT = "Default"
}

export const FILTER_TEAM: string[] = ["Sales", "Tech","Default"];
export enum TEAM {
  SALES = "Sales",
  TECH = "Tech",
  DEFAULT = "Default",
}

export enum FILTER_BY {
  STATUS = "status",
  DATE = "date",
  SEARCH = "search",
  CATEGORY = "category"
}

export const FILTER_CATEGORY: string[] = ["Allfunds", "Direct", "Non Funds","All Requests"];

export enum HUBWISE_CATEGORY {
  ALL_FUNDS= "Allfunds",
  DIRECT = "Direct",
  NON_FUNDS= "Non Funds",
  DEFAULT = "All Requests"
}

export enum HUBWISE_CATEGORY_TEMP {
  NOT_IN_CATALOG= "To be requested - AllFunds (not in catalogue)",
  IN_CATALOG = "To be requested - AllFunds (in catalogue)",
  DIRECT_FM = "To be requested - Direct to FM (existing relationship)",
  REQUEST_FM = "Set up and available",
}

export const HUBWISE_REPORT_TYPES= ["Summary", "Detailed"] as HUBWISE_REPORT_TYPE[]
export enum HUBWISE_REPORT_TYPE {
  SUMMARY = "Summary",
  DETAILED = "Detailed"
}
