// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    ContributionsContainer
} from "styles/sippAdmin/sippClient.styled";
import { CONTRIBUTION_TYPE_TAB, CONTRIBUTION_TYPE_TABS, NEW_APPLICATION_SIPP_TAB } from "constants/sippAdminMenuItems";
import { FunctionComponent, useEffect, useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { 
    Container, 
    Title,
    SubTitle,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    FormValue,
    InputWrapper,
    Input,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { TemporarySippNewApplicationType } from "types/funds";

const SIPPClientAdvisorFeesComponent: FunctionComponent<{
    setTab: React.Dispatch<React.SetStateAction<NEW_APPLICATION_SIPP_TAB>>;
    setMainFormDetails: React.Dispatch<React.SetStateAction<TemporarySippNewApplicationType | undefined>>;
    mainFormDetails?: TemporarySippNewApplicationType
}> = ({
    setTab,
    setMainFormDetails,
    mainFormDetails
}) => {

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CONFIRM)
    };

    const handleBack = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setTab(NEW_APPLICATION_SIPP_TAB.CONTRIBUTION_TYPES)
    };

    useEffect(()=>{
        setMainFormDetails(prevState=>({...prevState, "Financial Advisor Details": {"Advisor Fees": "£30"}}))
    },[])

  return (
    <ContributionsContainer>
        <SubTitle>
            Advisor Fees
        </SubTitle>
        <QHolder onSubmit={handleSubmit}>
            <Section>
                <QAccordionHolder twoColumns>  
                    <QRow>
                        <FormLabel>{"Fees: "}</FormLabel>
                        <FormValue>{"£30"}</FormValue>
                    </QRow>
                </QAccordionHolder>
            </Section>
        </QHolder>
        <ContributionsContainer>
        <SubmitBtnHolder>
            <SubmitButton
                onClick={handleBack}
                text={"Back"}
                type={"submit"}
                disabled={false}
            />
            <SubmitButton
                onClick={handleSubmit}
                text={"Next"}
                type={"submit"}
                disabled={false}
            />
        </SubmitBtnHolder>
        </ContributionsContainer>
        
    </ContributionsContainer>
  );
}

export default SIPPClientAdvisorFeesComponent;
