import styled from "styled-components";

export const WrapTable = styled.div`
  margin-top: 25px;  
  white-space: nowrap;
  display: block;
  overflow: auto;
  max-height: 450px;
  padding: 5px;
`;


export const RespTable = styled.table`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.thead`
  display: table-header-group;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  word-break: break-word; // this is a temporary fixd for table width
`;

export const TableHeaderRow = styled.tr`
  background: rgba(224, 226, 236);
  position: sticky;
  top: 0;
`;

export const TableHeaderCell = styled.th`
  display: table-cell;
  padding: 18px;
  text-align: left;
`;

export const RespTableBody = styled.tbody`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.tr<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-3px);
  }
`;

export const TableBodyCell = styled.td`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;

  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
  `;

export const SelectedClientHolder= styled.div`
  width: 97%;
  padding: 20px 0;
  border-top: 1px solid #808080;
`;

export const CloseIconHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const CloseIcon = styled.div<{ iconURL: string }>`
  top: 10px;
  right: 40px;
  width: 32px;
  height: 32px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const closeIcon = `${process.env.PUBLIC_URL}/assets/crown-alert-close-icon.svg`;

export const SingleRow = styled.div`
  display: grid;
  grid-template-columns: 25% 75% 1fr;
  gap: 15px;
  margin-bottom: 27px;
  align-items: center;
  gap: 32px;
  text-align: left;
`;

export const DetailsIconWrapper = styled.div`
  width: 19px;
  height: 19px;
  padding-left: 10px;
  display: none;
  cursor: pointer;
`;

export const FieldName = styled.div`
  flex-basis: 160px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 16px;
  line-height: 21.09px;
  text-transform: capitalize;
  font-family: Roboto Condensed;
  text-align: left;
`;

export const Span = styled.div`
  @media (min-width: 1024px) {
    max-width: 600px;
    word-wrap: break-word;
  }
`;

export const FieldValue = styled.div`
  color: ${({ theme }) => theme.colors.DARK[400]};
  font-size: 16px;
  line-height: 18.75px;
  flex: 1;
  text-align: left;
  font-family: Roboto Condensed;
  display: flex;
  &:hover > ${DetailsIconWrapper} {
    display: block;
  }
`;

export const AccordionRowsHolder= styled.div<{twoColumns?: boolean}>`
  display: grid;
  grid-template-columns: ${({ twoColumns }) => (twoColumns ? "repeat(2, 1fr)" : "repeat(1,1fr)")};
  gap: 15px;
  margin: 20px 0;
`;

export const CommentsSection = styled.div`
  padding: 10px;
`;
export const NewCommentHolder= styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const AddCommentButton = styled.button`
  font-size: 15px;
  font-weight: 600;
  background:rgb(236, 238, 240);
  border-radius: 5px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  box-shadow: 0 2px 2px rgba(36, 7, 37, 0.1);
  border: none;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-3px);
  }
`;

export const DarkLayer = styled.div`
  z-index: 10;
  position: fixed;
  top: 72px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.DARK[500]};
  opacity: 20%;
`;

export const CommentsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 15px;
  margin: 0 auto;
  padding: 2px;
`;

export const CommentInfoHolder = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 15px;
  margin: 0 auto;
  padding: 2px;
  align-items: center;
  text-wrap: auto;
`;

export const LeftAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
`;
