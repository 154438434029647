import { useEffect } from "react";
import {
  ContentContainer,
  Title,
  CloseIcon,
  closeIcon,
} from "styles/crownAlert/CrownAlert.styled";
import { toggleScroll } from "utils";
import { DarkLayer } from "styles/sippAdmin/clientProfile.styled";
import { 
    QHolder,
    QAccordionHolder,
    Section,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder,
    MainContainer,
    ConfirmationContainer 
} from "styles/sippAdmin/sippAdmin.styled";
import { useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { addAirtableRecord } from "services/airTable";
import { useAppData } from "hooks/useAppData";
import {
  assetDir,
  GreenIcon
} from "styles/AlertFeedback.styled";

function NewCommentComponent({
  alertIsActive,
  menuToggle,
  header,
  component,
  setUpdate
}: {
  alertIsActive: boolean;
  menuToggle: (a?: string, b?: string) => void;
  header?: boolean;
  component?: string;
  setUpdate: React.Dispatch<React.SetStateAction<number>>
}) {
  useEffect(() => {
    toggleScroll(alertIsActive);
  }, [alertIsActive]);

  function handleCloseModal() {
    menuToggle();
  }

  const { user } = useAppData();

  const formObj = {
        "Title": "",
        "Comment": ""
    }
    const [formValues, setFormValues] = useState<{[key: string]: any}>(formObj); 
    const [confirmation, setConfirmation] = useState(false);
    const handleInputChange = (label: string, value: string) => {
    setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        //TODO: Send formValues to backend
        console.log("Form submitted:", formValues);

        const records = { 
            "records": [
                {
                    "fields": {
                        "Status": "Todo",
                        "Assignee": {
                            "email": "ioanabac@wegetdata.io"
                        },
                        "Component": component,
                        "Notes": formValues.Comment,
                        "Name": formValues.Title,
                        "User": user?.name ? user.name : "Unknown"
                    }
                }
            ]
        }
        addAirtableRecord("SIPP Admin Comments", records).then((response) => {
            console.log("add task response: ", response)
        })
        setConfirmation(true);
        setUpdate((prev) => prev + 1);
    };

  return (
    <div style={{ display: alertIsActive ? "flex" : "none" }} data-testid="cont">
      <DarkLayer />
      <MainContainer header={header}>
        <CloseIcon iconURL={closeIcon} onClick={handleCloseModal} />
        { !confirmation ?
        <ContentContainer>
          <Title>
            <b>New Comment</b>
          </Title>
            <QHolder onSubmit={handleSubmit}>
                <Section>
                    <QAccordionHolder>  
                        {Object.entries(formValues).map(([label, value]) => (
                            <QRow key={label}>
                                <FormLabel htmlFor={label}>{label}</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={label}
                                        value={value}
                                        onChange={(e) => handleInputChange(label, e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                        ))}
                    </QAccordionHolder>
                </Section>
            </QHolder>
            <SubmitBtnHolder><SubmitButton text="Submit" onClick={handleSubmit} type="submit" disabled={formValues["Comment"].trim() === "" ? true : false}/></SubmitBtnHolder>
        </ContentContainer>
        : 
        <ConfirmationContainer>
          <FormLabel>Thank you for your feedback!</FormLabel>
          <GreenIcon src={`${assetDir}checkmate-verified.svg`} alt="green status" />
        </ConfirmationContainer>
        }
      </MainContainer>
    </div>
  );
}
export default NewCommentComponent;
