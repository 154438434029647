// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import NoDataContent from "components/noData/NoDataContent";
import { 
    CommentsSection,
    NewCommentHolder,
    AddCommentButton,
    CommentsHolder,
    CommentInfoHolder,
    LeftAvatarContainer 
} from "styles/sippAdmin/clientProfile.styled";
import { CommentIcon } from "styles/funds/index.styled";
import { Typography, Card, CardContent } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { format } from "date-fns";
import {
    Status,
  } from "../../../styles/admin/AdminTable.styled";
  import { useAppData } from "hooks/useAppData";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { AirTableRecord } from "types";
import LoadingSpinner from "components/LoadingSpinner";

const ClientProfileCommentsSectionComponent: FunctionComponent<{ 
    handleCommentClick: (component: string) => void;
    handleEditComment: (component: AirTableRecord) => void;
    title: string;
    airtableData: any[];
    loadingAirtable: boolean;
}> = ({
    handleCommentClick,
    title,
    airtableData,
    handleEditComment,
    loadingAirtable
}) => {

    const commentIcon = `${process.env.PUBLIC_URL}/assets/comment-icon3.svg`;
    const { user } = useAppData();

  return (
        <CommentsSection>
                <NewCommentHolder>
                    <AddCommentButton onClick={()=>handleCommentClick(title)}>New Comment <CommentIcon src={commentIcon} alt="comment-icon" /></AddCommentButton>
                </NewCommentHolder>
                <Accordion title="Comments" light noTopBorder small>
                    {loadingAirtable ? <LoadingSpinner /> : 
                    <>
                        <CommentsHolder>
                            {/* Display existing comments */}
                            { airtableData.length > 0 ?
                                airtableData.map((comment) => (
                                    comment.fields.Visible === "true" &&
                                    <Card key={comment.id}>
                                        <CardContent>
                                            <CommentInfoHolder>
                                                <LeftAvatarContainer>
                                                    <Person />
                                                    <Typography variant="body1" color="textSecondary"> 
                                                    {comment.fields.User}
                                                    </Typography>
                                                </LeftAvatarContainer>
                                                <div>
                                                    <Status status={comment.fields.Status === "In progress" ? "CLOSED" : comment.fields.Status === "Todo" ? "TODO" : "ACTIVE"} >
                                                        {comment.fields.Status}
                                                    </Status>
                                                    <Typography variant="body1">{comment.fields.Name}</Typography>
                                                    <Typography variant="body2" color="textSecondary">{ `Created on: 
                                                    ${format(new Date(comment.fields["Time Created"]), "dd MMM yyy")}`}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">{`Last Updated: 
                                                    ${format(new Date(comment.fields["Last Update Time"]), "dd MMM yyy")}`}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Comment: {comment.fields.Notes}
                                                    </Typography>
                                                </div>
                                                <>{comment.fields.User === user?.name && 
                                                    <SubmitButton text="Edit" onClick={() => handleEditComment(comment)} type="submit" disabled={false} />
                                                
                                                }</>
                                            </CommentInfoHolder>
                                        </CardContent>
                                    </Card> ))
                                    : <NoDataContent /> }
                        </CommentsHolder>
                    </>}
                </Accordion>
        </CommentsSection>
  );
}

export default ClientProfileCommentsSectionComponent;
